<template>
  <div class="sws-head">
    <div class="sws-head-bg">
      <div class="sws-head-bg-1"></div>
      <div class="sws-head-bg-2"></div>
      <div class="sws-head-bg-3"></div>
      <div class="sws-head-bg-4"></div>
      <div class="sws-head-bg-5"></div>
    </div>
    <div class="sws-head-datetime">
      <div class="sws-head-date">
        {{dateStr}}
      </div>
      <div class="sws-head-time">
        <i id="timeHour1" :style="{'background-position-y':timeHour1PY}"></i>
        <i id="timeHour2" :style="{'background-position-y':timeHour2PY}"></i>
        <i id="timeSplit"></i>
        <i id="timeMin1" :style="{'background-position-y':timeMin1PY}"></i>
        <i id="timeMin2" :style="{'background-position-y':timeMin2PY}"></i>
      </div>
    </div>
    <div class="sws-head-warningmode" title="点击切换模式" data-val="1">
        无人值守模式
    </div>
    <div class="sws-head-title">
      <div class="sws-head-title-wrap">
          <div>
              <span>{{documentTitle}}</span>
          </div>
        </div>
    </div>
    <div class="sws-head-tool">
      <ul>
        <li class="sws-head-tool-item" @click="toggleFullScreen($event)" title="全屏/退出全屏"><i class="iconfont">&#xe70d;</i></li>
        <li class="sws-head-tool-item" v-if="appConfig.ServiceBit">
          <i class="iconfont">&#xe736;</i>
          <ul>
              <li class="menu-service" @click="open('service','服务产品')">服务产品</li>
          </ul>
        </li>
        <li class="sws-head-tool-item">
          <i class="iconfont">&#xe722;</i>
          <ul>
            <li @click="open('myStation','重点实况站点管理')">重点实况站点管理</li>
            <li @click="open('metarThresholdSta','自动站阀值设置')">自动站阀值设置</li>
            <li @click="open('metarThresholdCusSta','自定义站阀值设置')">自定义站阀值设置</li>
            <li @click="open('threshold','推送管理')">推送管理</li>
            <li v-if="cusInfo.AppCode === 'bameng_sws'" @click="open('thresholdAlertPre','警戒值管理')">警戒值管理</li>
            <li @click="open('warningMode','预警模式管理')">预警模式管理</li>
            <li @click="open('msgSmsLog','短信通知记录')">短信通知记录</li>
            <li @click="open('msgCallLog','语音消息通知记录')">语音消息通知记录</li>
            <li @click="open('msgWechatLog','公众号消息通知记录')">公众号消息通知记录</li>
            <li @click="open('warningRec','历史气象灾害预警')">历史气象灾害预警</li>
            <li v-if="appConfig.WRBit"><a href="https://wr2.bjzkzm.com/" target="_blank">报表制作系统</a></li>
          </ul>
        </li>
        <li class="sws-head-tool-item" title="进入后台"><a href="http://swsm.bjzkzm.com" target="_blank"><i class="iconfont">&#xe738;</i></a></li>
        <li class="sws-head-tool-item">
          <i class="iconfont">&#xe710;</i>
          <ul>
            <li>修改密码</li>
            <li><a @click="loginOut">登出</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'TopNav',
  props: ['cusInfo', 'documentTitle', 'appConfig'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      cusInfo: props.cusInfo,
      documentTitle: props.documentTitle,
      appConfig: props.appConfig,
      dateStr: '',
      timeHour1PY: '0',
      timeHour2PY: '0',
      timeMin1PY: '0',
      timeMin2PY: '0',
      wrBit: false
    })
    const setDataTime = () => {
      const now = new Date()
      state.dateStr = now.getFullYear() + '年' + (now.getMonth() + 1) + '月' + now.getDate() + '日' + ' ' + getWeek(now.getDay())

      state.timeHour1PY = getDateTimePos(Math.floor(now.getHours() / 10)) + 'px'
      state.timeHour2PY = getDateTimePos(now.getHours()) + 'px'
      state.timeMin1PY = getDateTimePos(Math.floor(now.getMinutes() / 10)) + 'px'
      state.timeMin2PY = getDateTimePos(now.getMinutes()) + 'px'

      setTimeout(function () { setDataTime() }, 1000)
    }
    const getDateTimePos = (num) => {
      return (0 - num * 40)
    }
    const getWeek = (num) => {
      switch (num) {
        case 0:
          return '星期日'
        case 1:
          return '星期一'
        case 2:
          return '星期二'
        case 3:
          return '星期三'
        case 4:
          return '星期四'
        case 5:
          return '星期五'
        case 6:
          return '星期六'
      }
    }
    const toggleFullScreen = (e) => {
      const elem = document.body
      let ele = e.target
      if (ele.tagName !== 'I') {
        ele = ele.children[0]
      }
      if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
        ele.innerHTML = '&#xe70e;'
        if (elem.requestFullScreen) {
          elem.requestFullScreen()
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        }
      } else {
        ele.innerHTML = '&#xe70d;'
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    }
    const open = (typeName, titleName) => {
      emit('openSetupWin', typeName, titleName)
    }
    const loginOut = () => {
      store.commit('delToken')
      store.commit('delUserName')
      store.commit('delAppCode')
      store.commit('delNickName')

      const url = '#/login/'
      location.href = url
    }
    onMounted(() => {
      setTimeout(function () {
      }, 1000)
      setDataTime()
    })
    return {
      ...toRefs(state),
      setDataTime,
      getWeek,
      getDateTimePos,
      toggleFullScreen,
      open,
      loginOut
    }
  }
}
</script>
<style lang="scss">
$img:"../assets/images";
@import "../assets/styles/topnav.scss";  //引入方式
</style>
