<template>
  <div class="sws-middle">
    <div class="sws-middle12 sws-modal">
      <div class="sws-model-tool">
        <i class="iconfont prev">&#xe712;</i>
        <i class="iconfont next">&#xe718;</i>
        <i class="iconfont close" @click="close">&#xe70f;</i>
      </div>
      <div class="sws-middle-title">
        {{dataWarning.dataGroup[displayIndex[0]].EventType_CN + dataWarning.dataGroup[displayIndex[0]].Severity_CN + '预警'}}
      </div>
      <div class="sws-middle-wrap">
        <div class="sws-middle-menu">
          <ul class="pull-left" id="swsStainfoNav">
            <li v-for="(item, index) in dataWarning.dataGroup[displayIndex[0]].data" :key="index" v-bind:class="{'active':index === 0}" @click="slideTo">{{item.Sender.replace('气象台','').replace('气象局','')}}</li>
          </ul>
        </div>
        <swiper
          :modules="modules"
          :scrollbar="{ draggable: true }"
          :autoplay="{ delay: 30000 }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          class="sws-stainfo"
        >
          <swiper-slide v-for="(item, index) in dataWarning.dataGroup[displayIndex[0]].data" :key="index" class="sws-stainfo-metar">
            <div class="sws-warning-des">
              {{item.Description}}
            </div>
            <div class="sws-warning-def">
              <div class="title">预报措施：</div>
              <div v-for="(item2, index2) in dataWarning.dataGroup[displayIndex[0]].set[index].Defense.split('|')" :key="index2">
                {{item2}}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

export default {
  name: 'MapWarningInfo',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['dataWarning', 'displayIndex'],
  setup (props, { emit }) {
    const state = reactive({
      mySwiper: {},
      dataWarning: props.dataWarning,
      displayIndex: props.displayIndex
    })
    const onSwiper = (swiper) => {
      state.mySwiper = swiper
    }
    const slideTo = (e) => {
      const element = e.target
      const parent = element.parentElement
      const childs = parent.getElementsByTagName('li')
      for (let i = 0; i < childs.length; i++) {
        if (childs[i] === element) {
          state.mySwiper.slideTo(i)
        }
      }
    }
    const onSlideChange = () => {
      const swsStainfoNav = document.getElementById('swsStainfoNav')
      const lis = swsStainfoNav.getElementsByTagName('LI')
      for (let i = 0; i < lis.length; i++) {
        if (i === state.mySwiper.realIndex) {
          lis[i].className = 'active'
        } else {
          lis[i].className = ''
        }
      }
    }

    const close = () => {
      emit('close')
    }
    onMounted(() => {
      console.log(props.dataWarning)
    })
    return {
      ...toRefs(state),
      modules: [Autoplay],
      slideTo,
      onSwiper,
      onSlideChange,
      close
    }
  }
}
</script>
