<template>
  <div class="sws-setup-head">
      <div class="filter">
        <input v-model="keyword" placeholder="搜索站号/站名" @change="loadList" />
      </div>
      <div class="edit">
          <button @click="download">下载最近1年</button>
      </div>
  </div>
  <div class="sws-setup-body">
    <div class="table-head">
      <table>
        <colgroup>
          <col style="width:55%;">
          <col style="width:24%;">
          <col style="width:16%;">
          <col style="width:5%;">
        </colgroup>
        <thead>
          <tr>
            <th>标题</th>
            <th>发布单位</th>
            <th>发布时间</th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="table-body">
      <table>
        <colgroup>
          <col style="width:55%;">
          <col style="width:24%;">
          <col style="width:16%;">
          <col style="width:5%;">
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in listData" :key="index">
            <td :title="item.Station_Name">{{item.Headline}}</td>
            <td :title="item.Station_ID_C">{{item.Sender}}</td>
            <td :title="item.AliasName">{{item.SendTime}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(index)">&#xec88;</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-foot">
      <PagingHelper v-if="pagingInfo != ''" :pagingInfo="pagingInfo" @goPage="goPage" :key="pagingKey"></PagingHelper>
    </div>
  </div>
  <div v-if="warningInfo != ''" class="sws-modal-mask">
    <div class="sws-modal">
      <div class="sws-modal-head">
        <div class="sws-modal-tool">
          <i class="iconfont close" @click="closeEdit">&#xe70f;</i>
        </div>
        <div class="title">
          预警详情
        </div>
      </div>
      <div class="sws-modal-body">
        <el-form label-width="120px">
          <el-form-item label="标题">
            <el-input v-model="warningInfo.Headline" />
          </el-form-item>
          <el-form-item label="预警内容">
            <el-input v-model="warningInfo.Description" type="textarea" rows="10" />
          </el-form-item>
          <el-form-item label="发布单位">
            <el-input v-model="warningInfo.Sender" />
          </el-form-item>
          <el-form-item label="发布时间">
            <el-input v-model="warningInfo.SendTime" />
          </el-form-item>
        </el-form>
      </div>
      <div class="sws-modal-foot"></div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import PagingHelper from './PagingHelper'
import { useStore } from 'vuex'
import common from '@/assets/js/common'

export default {
  name: 'SetupWarningRec',
  components: {
    PagingHelper
  },
  props: [],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      keyword: '',
      listData: [],
      pagingInfo: '',
      pagingKey: 0,
      warningInfo: '',
      expExcel: false
    })
    // 数据列表
    const loadList = (page = 1) => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataWarning/List?keyword=' + state.keyword + '&page=' + page
      }).then(function (res) {
        console.log(res)
        res.data.WarningDatas.forEach(function (ele, index, arr) {
          ele.SendTime = common.timeToStrDate(ele.SendTime)
        })
        state.listData = res.data.WarningDatas
        state.pagingInfo = res.data.PagingInfo
        state.pagingKey++
      })
    }
    // 加载指定页
    const goPage = (page) => {
      loadList(page)
    }
    // 打开编辑页
    const edit = (index) => {
      console.log(state.listData[index])
      state.warningInfo = state.listData[index]
    }
    // 关闭编辑页
    const closeEdit = () => {
      state.warningInfo = ''
    }
    const download = () => {
      if (!state.expExcel) {
        state.expExcel = true
        axios.request({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Custom-Token': state.token,
            'User-Card': state.userName,
            'App-Code': state.appCode
          },
          url: 'https://swsapi.bjzkzm.com/DataWarning/Download?keyword=' + state.keyword
        }).then(function (res) {
          if (res.data.State === 'ok') {
            window.location.href = res.data.Value
          }
        }).finally(function () {
          state.expExcel = false
        })
      }
    }
    onMounted(() => {
      loadList()
    })
    return {
      ...toRefs(state),
      goPage,
      loadList,
      edit,
      closeEdit,
      download
    }
  }
}
</script>
