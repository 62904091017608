<template>
  <div class="sws-middle">
    <div class="sws-middle23 sws-modal">
      <div class="sws-model-tool">
          <i class="iconfont close" @click="close">&#xe70f;</i>
      </div>
      <div class="sws-middle-title">
          {{setupViewInfo.title}}
      </div>
      <div class="sws-setup-list">
        <SetupMyStation v-if="setupViewInfo.type === 'myStation'"></SetupMyStation>
        <SetupMetarThresholdSta v-if="setupViewInfo.type === 'metarThresholdSta'"></SetupMetarThresholdSta>
        <SetupMetarThresholdCusSta v-if="setupViewInfo.type === 'metarThresholdCusSta'"></SetupMetarThresholdCusSta>
        <SetupThreshold v-if="setupViewInfo.type === 'threshold'"></SetupThreshold>
        <SetupThresholdAlertPre v-if="setupViewInfo.type === 'thresholdAlertPre'"></SetupThresholdAlertPre>
        <SetupWarningMode v-if="setupViewInfo.type === 'warningMode'"></SetupWarningMode>
        <SetupMsgSmsLog v-if="setupViewInfo.type === 'msgSmsLog'"></SetupMsgSmsLog>
        <SetupMsgCallLog v-if="setupViewInfo.type === 'msgCallLog'"></SetupMsgCallLog>
        <SetupMsgWechatLog v-if="setupViewInfo.type === 'msgWechatLog'"></SetupMsgWechatLog>
        <SetupWarningRec v-if="setupViewInfo.type === 'warningRec'"></SetupWarningRec>
        <SetupService v-if="setupViewInfo.type === 'service'"></SetupService>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import SetupMyStation from './SetupMyStation'
import SetupMetarThresholdSta from './SetupMetarThresholdSta'
import SetupMetarThresholdCusSta from './SetupMetarThresholdCusSta'
import SetupThreshold from './SetupThreshold'
import SetupThresholdAlertPre from './SetupThresholdAlertPre'
import SetupWarningMode from './SetupWarningMode'
import SetupMsgSmsLog from './SetupMsgSmsLog'
import SetupMsgCallLog from './SetupMsgCallLog'
import SetupMsgWechatLog from './SetupMsgWechatLog'
import SetupWarningRec from './SetupWarningRec'
import SetupService from './SetupService'

export default {
  name: 'SetupView',
  components: {
    SetupMyStation,
    SetupMetarThresholdSta,
    SetupMetarThresholdCusSta,
    SetupThreshold,
    SetupThresholdAlertPre,
    SetupWarningMode,
    SetupMsgSmsLog,
    SetupMsgCallLog,
    SetupMsgWechatLog,
    SetupWarningRec,
    SetupService
  },
  props: ['setupViewInfo'],
  setup (props, { emit }) {
    const state = reactive({
      setupViewInfo: props.setupViewInfo
    })
    const close = () => {
      emit('closeSetup')
    }
    onMounted(() => {

    })
    return {
      ...toRefs(state),
      close
    }
  }
}
</script>
<style lang="scss">
$img:"../assets/images";
@import "../assets/styles/setup.scss";  //引入方式
</style>
